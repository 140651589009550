import React, { useCallback, useEffect, useState } from "react";
import { GrMenu } from "react-icons/gr";
import { LiaTimesSolid } from "react-icons/lia";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import { useVisibleContext } from "./VisibleContext";
import { useCommonData } from "./CommonData";
import { useTranslation } from "./TranslationContext";

const Header = () => {
  const { serviceData, pageData  } = useCommonData();

  const about = pageData?.data
    ?.filter((item) => item.id === 1)
    ?.map((item) => item.slug);

  const { translate, changeLanguage, currentLanguage, Language } = useTranslation();

  const [ lang, setLang ] = useState("az");

  const { isOpen, toggleMenu } = useVisibleContext();
  const router = useRouter();

  const { visible, setVisible } = useVisibleContext();

  const handleClick = () => {
    setVisible(!visible);
  };

  const scrollToAdvantage = useCallback(() => {
    let scrollToPosition;
    if (window.innerWidth <= 650) {
      scrollToPosition = 620;
    } else if (window.innerWidth <= 1100) {
      scrollToPosition = 1050;
    } else {
      scrollToPosition = 1250;
    }

    if (router.pathname !== `${currentLanguage}`) {
      router.push(`/${currentLanguage}`).then(() => {
        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth",
        });
      });
    } else {
      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  }, []);
  
  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;

    changeLanguage(newLanguage);
    setLang(newLanguage);

    const { pathname, query } = router;

    let newPathname;

    if (pathname.includes("[lang]") && pathname.includes("[slug]")) {
      newPathname = `/${newLanguage}`;
    } else if (pathname.includes("[lang]") && pathname.includes("[id]")) {
      newPathname = pathname.replace(/\[lang\]/, newLanguage).replace(/\[id\]/, query?.id);
    } else if (pathname.includes("[lang]")) {
      newPathname = pathname.replace(/\[lang\]/, newLanguage);
    } else {
      newPathname = `/${newLanguage}`;
    }

    router.push({ pathname: newPathname });
  };

  return (
    <>
      <div className="">
        <Image
          src="/assets/ellipse.png"
          width={160}
          height={100}
          className="absolute top-0 right-0 z-10  max-xxl:hidden"
          alt=""
        />
      </div>
      <div
        className={`w-full  bg-[#5B2D90] relative z-[1] drop-shadow-lg 
                      ${
                        router.pathname !== `/[lang]/faq` &&
                        router.pathname !== `/[lang]/payment` &&
                        router.pathname !== `/[lang]/career` &&
                        router.pathname !== `/[lang]/blog` &&
                        router.pathname !== `/[lang]/blog/[slug]` &&
                        router.pathname !== `/[lang]/news` &&
                        router.pathname !== `/[lang]/news/[slug]` &&
                        router.pathname !== `/[lang]/campaigns` &&
                        router.pathname !== `/[lang]/speedtest` &&
                        router.query.slug !== `installment-payment` &&
                        router.query.slug !== `takistle-ödeme` &&
                        router.query.slug !== `about-us` &&
                        router.query.slug !== `haqqimizda`
                          ? "rounded-bl-[15px] rounded-br-[15px]"
                          : ""
                      }
                      ${
                        router.query.slug === `about-us` ||
                        router.query.slug === `haqqimizda`
                          ? "xl:rounded-bl-[15px] xl:rounded-br-[15px]"
                          : ""
                      }
                        `}
      >
        <div className="container max-w-[1100px] mx-auto  py-2 flex justify-between items-start border-b-[1px] border-black border-opacity-30 max-sm:items-center max-lg:items-center">
          <ul className="flex justify-center items-center gap-5 text-white max-xl:hidden">
            <li className="">
              <Link href={`/${currentLanguage}`} className="">
                <Image
                  src="/assets/logo2.png"
                  width={182}
                  height={180}
                  style={{ width: "182px", height: "33px" }}
                  className="w-[182px]"
                  alt=""
                />
              </Link>
            </li>
            <li className="">
              <Link href={`/${currentLanguage}/faq`}>
                {" "}
                {translate("FAQ", Language)}
              </Link>
            </li>
            <li className="">
              <Link href={`/${currentLanguage}/payment`}>
                {" "}
                {translate("Payment", Language)}
              </Link>
            </li>
            <li className="">
              <Link href={`/${currentLanguage}/career`}>
                {" "}
                {translate("Career", Language)}
              </Link>
            </li>
            <li className="">
              <Link href={`/${currentLanguage}/blog`}>
                {translate("Blog", Language)}
              </Link>
            </li>
            <li className="">
              <Link href={`/${currentLanguage}/news`}>
                {translate("News", Language)}
              </Link>
            </li>
          </ul>
          <ul className="flex justify-center items-center">
            <li>
              <Link
                href={`/${currentLanguage}`}
                className="hidden max-xl:block "
              >
                {" "}
                <Image
                  src="/assets/logo.png"
                  width={99}
                  style={{ width: "100%", height: "100%" }}
                  height={88}
                  priority
                  className="max-xxl:w-[99px] max-xxl:ml-3"
                  alt=""
                />
              </Link>
            </li>
          </ul>
          <ul className="flex  gap-2 max-sm:gap-1 ">
            <li className="overflow-hidden">
              <a
                href="tel:8220"
                className="text-white flex items-center gap-2 max-sm:gap-1 overflow-hidden"
              >
                <div className="container bg-white mx-auto w-7 h-7 rounded-full flex justify-center items-center overflow-hidden">
                  <Image
                    src="/assets/cellphone.png"
                    width={300}
                    height={500}
                    className="w-6 h-7 max-sm:w-5 max-sm:h-6"
                    alt=""
                  />
                </div>
                <span className="text-[15px] font-medium max-sm:text-[12px]">
                  8220
                </span>
              </a>
            </li>
            <li className="text-white flex items-center gap-1 max-sm:gap-0  max-sm:border-r-[2px] max-xl:border-r-[2px] ">
              <div className="container bg-white mx-auto w-7 h-7 rounded-full flex justify-center items-center ">
                <Image
                  src="/assets/world.png"
                  width={300}
                  height={500}
                  className="w-4  max-sm:h-4"
                  alt=""
                />
              </div>
              <div className="">
                <select
                  id="countries"
                  className="text-white bg-inherit border-none text-[15px] max-sm:text-[12px]  font-medium rounded-lg block w-full p-0 focus:ring-0 select cursor-pointer"
                  onChange={handleLanguageChange}
                  value={currentLanguage}
                >
                  <option className="text-black" value="az">
                    AZE
                  </option>
                  <option className="text-black" value="en">
                    ENG
                  </option>
                </select>
              </div>
            </li>
            <li className="hidden max-xl:block   max-sm:mr-1  max-xl:mr-5">
              <div
                className="container bg-white mx-auto w-7 h-7 rounded-full flex justify-center items-center "
                onClick={toggleMenu}
              >
                {isOpen ? <LiaTimesSolid /> : <GrMenu />}
              </div>
            </li>
          </ul>
        </div>
        <div className="container max-w-[1087px] py-4 mx-auto  max-xl:hidden ">
          <div className="flex  justify-between ">
            <ul className="navbar-items  max-sm:hidden flex items-center  gap-5 text-white text-[13px]  font-medium tracking-[0.5px]">
              <li className="">
                <Link href={`/${currentLanguage}/${about}`}>
                  {translate("About_us", Language)}
                </Link>
              </li>
              <li className="cursor-pointer" onClick={scrollToAdvantage}>
                {translate("Our_advantages", Language)}
              </li>
              <li className="">
                <button onClick={handleClick}>
                  {translate("Services", Language)}
                </button>
                {visible &&
                  location.pathname !== "/services/fiberoptik" &&
                  location.pathname !== "/services/iptv" &&
                  location.pathname !== "/services/simsiz" &&
                  location.pathname !== "/services/adsl" &&
                  location.pathname !== "/services/aix" && (
                    <div className="absolute mt-3 triangle w-0 h-0 border-l-[35px] border-l-transparent border-b-[20px] border-b-white border-r-[35px] border-r-transparent"></div>
                  )}
              </li>
              <li className="">
                <Link href={`/${currentLanguage}/campaigns`}>
                  {" "}
                  {translate("Campaigns", Language)}
                </Link>
              </li>
              <li className="">
                <Link href={`/${currentLanguage}/useful`}>
                  {" "}
                  {translate("Useful", Language)}
                </Link>
              </li>
              {
                /*
                <li className="">
                <Link href={`/${currentLanguage}/speedtest`}>
                  {" "}
                  {translate("Speedtest", Language)}
                </Link>
              </li>
                */
              }
            </ul>
            <ul className="navbar-items  max-sm:hidden flex items-center  gap-5 text-white text-[12px]  font-medium tracking-[0.5px]">
              {/* <li className="border p-2 rounded-3xl bg-white text-[#5B2D90] w-[139px] h-[35px] flex justify-center items-center">
                <Link href={`/${currentLanguage}/${installment}`}>
                  {translate('Installment', Language)}
                </Link>
              </li> */}
              <li className="border p-2 rounded-3xl bg-white text-[#5B2D90] w-[104px] h-[35px] flex justify-center items-center">
                <Link href={`/${currentLanguage}/registration`}>
                  {translate("Registration", Language)}
                </Link>
              </li>
              <Link href="https://kabinetim.azeronline.com/" target="_blank">
                <li className="border p-2 rounded-3xl w-[130px] h-[40px] flex justify-center items-center">
                  {translate("Private_cabinet", Language)}
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="absolute   bg-white right-0 top-[43px] border-t-[2px] rounded-t-[5px] border-[#5B2D90] max-sm:w-[300px] max-md:w-[300px] max-lg:w-[300px] max-xl:w-[300px] flex justify-center items-center gap-10 pt-10 pb-5 pl-10 max-sm:pr-0 max-xl:pr-7 rounded-b-3xl nav-menu z-[100]">
          <ul className="flex flex-col gap-4 justify-center items-end  z-10">
            {/* <li
              className="border  rounded-3xl bg-[#5B2D90] text-white w-[170px] h-[40px] flex justify-center items-center"
              onClick={toggleMenu}
            >
              <Link href={`/${currentLanguage}/${installment}`}>
                {' '}
                {translate('Installment', Language)}
              </Link>
            </li> */}

            <Link href="https://kabinetim.azeronline.com/" target="_blank">
              <li
                className="border  rounded-3xl bg-[#5B2D90] text-white w-[170px] h-[40px] flex justify-center items-center"
                onClick={toggleMenu}
              >
                {translate("Private_cabinet", Language)}
              </li>
            </Link>

            <li
              className="border border-[#5B2D90] text-[#5B2D90] p-2 rounded-3xl w-[170px] h-[40px] text-center overflow-hidden"
              onClick={toggleMenu}
            >
              <Link href={`/${currentLanguage}/registration`}>
                {translate("Registration", Language)}
              </Link>
            </li>

            <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/${about}`}>
                {translate("About_us", Language)}
              </Link>
            </li>
            <li className="" onClick={toggleMenu}>
              {serviceData?.data?.length > 0 ? (
                <Link href={`/${currentLanguage}/services/${serviceData.data[0].id}`}>
                  {translate("Services", Language)}
                </Link>
              ) : (
                <span>{translate("Services", Language)}</span>
              )}
            </li>
            <li
              className=""
              onClick={() => {
                toggleMenu();
                scrollToAdvantage();
              }}
            >
              <Link href={`/${currentLanguage}`}>
                {translate("Our_advantages", Language)}
              </Link>
            </li>
            <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/campaigns`}>
                {translate("Campaigns", Language)}
              </Link>
            </li>
            <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/useful`}>
                {translate("Useful", Language)}
              </Link>
            </li>
            {
              /*  
              <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/speedtest`}>
                {translate("Speedtest", Language)}
              </Link>
            </li>
              */
            }
            <div className="w-3/4 border-b-[1px] border-[#464646] " />
            <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/faq`}>
                {translate("FAQ", Language)}
              </Link>
            </li>
            <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/payment`}>
                {translate("Payment", Language)}
              </Link>
            </li>
            <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/career`}>
                {" "}
                {translate("Career", Language)}
              </Link>
            </li>
            <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/blog`}>
                {translate("Blog", Language)}
              </Link>
            </li>
            <li className="" onClick={toggleMenu}>
              <Link href={`/${currentLanguage}/news`}>
                {translate("News", Language)}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
