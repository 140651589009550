import React, { useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useCommonData } from './CommonData';
import { useRouter } from 'next/router';
import { useTranslation } from './TranslationContext';
import { useVisibleContext } from './VisibleContext';

const Footer = () => {
  const { translate, Language, currentLanguage } = useTranslation();
  const { pageData, settingData } = useCommonData();

  const router = useRouter();

  const about = pageData?.data
    ?.filter((item) => item.id === 1)
    ?.map((item) => item.slug);
  const privacy = pageData?.data
    ?.filter((item) => item.id === 2)
    ?.map((item) => item.slug);
  const facebook = settingData?.data
    ?.filter((item) => item.key === 'facebook_link')
    ?.map((item) => item.value);
  const instagram = settingData?.data
    ?.filter((item) => item.key === 'instagram_link')
    ?.map((item) => item.value);
  const linkedin = settingData?.data
    ?.filter((item) => item.key === 'linkedin_link')
    ?.map((item) => item.value);

  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const { visible, setVisible } = useVisibleContext();

  const handleClick = () => {
    setVisible(!visible);
  };

  const scrollToAdvantage = useCallback(() => {
    let scrollToPosition;
    if (window.innerWidth <= 650) {
      scrollToPosition = 620;
    } else if (window.innerWidth <= 1100) {
      scrollToPosition = 1050;
    } else {
      scrollToPosition = 1250;
    }

    if (router.pathname !== '/a') {
      router.push(`/${currentLanguage}`).then(() => {
        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth',
        });
      });
    } else {
      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div className="relative  w-full bg-[#5B2D90]">
      <div className="container max-w-5xl max-sm:w-full mx-auto py-14 max-sm:py-10 grid grid-cols-4 max-sm:grid-cols-2 max-sm:gap-5 ma max-lg:grid-cols-2 max-lg:gap-5 max-lg:px-10 ">
        <div className="flex items-start max-sm:col-span-2 max-lg:col-span-2">
          <Link href={`/${currentLanguage}`} onClick={handleScrollUp}>
            <Image
              src="/assets/logo2.png"
              width={182}
              height={180}
              className="w-[182px] max-sm:w-[141px]"
              alt=""
            />
          </Link>
        </div>
        <div className="text-white flex flex-col gap-5  justify-start items-start ">
          <h3 className="text-[20px] font-semibold tracking-[0.5px] max-sm:text-[16px]">
            <Link href={`/${currentLanguage}/${about}`}>
              {translate('About_us', Language)}
            </Link>
          </h3>
          <ul className="text-[14px] font-regular flex flex-col gap-3">
            <li className="cursor-pointer" onClick={scrollToAdvantage}>
              {translate('Our_advantages', Language)}
            </li>
            <li
              className="cursor-pointer"
              onClick={() => {
                handleClick();
                handleScrollUp();
              }}
            >
              {translate('Services', Language)}
            </li>
            <li className="">
              <Link
                href={`/${currentLanguage}/campaigns`}
                onClick={handleScrollUp}
              >
                {translate('Campaigns', Language)}
              </Link>
            </li>
            <li className="">
              <Link 
                href={`/${currentLanguage}/useful`}
                onClick={handleScrollUp}
              >
                {translate("Useful", Language)}
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-white flex flex-col gap-5 ml-10">
          <h3 className="text-[20px] font-semibold tracking-[0.5px] max-sm:text-[16px]">
            <Link href={`/${currentLanguage}/faq`} onClick={handleScrollUp}>
              {translate('FAQ', Language)}
            </Link>
          </h3>
          <ul className="text-sm font-light flex flex-col gap-3">
            <li className="">
              <Link
                href={`/${currentLanguage}/payment`}
                onClick={handleScrollUp}
              >
                {translate('Payment', Language)}
              </Link>
            </li>
            <li className="">
              <Link
                href={`/${currentLanguage}/career`}
                onClick={handleScrollUp}
              >
                {translate('Career', Language)}
              </Link>
            </li>
            <li className="">
              <Link 
                href={`/${currentLanguage}/blog`}
                onClick={handleScrollUp}>
                {translate("Blog", Language)}
              </Link>
            </li>
            <li className="">
              <Link
                href={`/${currentLanguage}/news`}
                onClick={handleScrollUp}
              >
                {translate('News', Language)}
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-white flex flex-col    justify-start items-start   gap-3 max-sm:col-span-2 max-lg:col-span-2">
          <h3 className="text-[20px] font-semibold  tracking-[0.5px] max-sm:text-[16px]  ">
            {translate('Contact_us', Language)}
          </h3>
          <ul className="text-sm font-light grid grid-cols-5 gap-2 justify-center items-center w-[200px] max-sm:w-[200px]  max-sm:flex max-lg:w-[200px]  max-lg:flex    ">
            <a href={`${facebook}`} target="_blank" rel="noopener noreferrer">
              <Image
                src="/assets/fb.png"
                width={300}
                height={500}
                className="w-7"
                alt=""
              />{' '}
            </a>

            <a href={`${instagram}`} target="_blank" rel="noopener noreferrer">
              <Image
                src="/assets/instagram.png"
                width={300}
                height={500}
                className="w-7"
                alt=""
              />
            </a>
            <a href={`${linkedin}`} target="_blank" rel="noopener noreferrer">
              <Image
                src="/assets/linkedin.png"
                width={300}
                height={500}
                className="w-7"
                alt=""
              />
            </a>

            <a
              href="tel:8220"
              className="flex justify-start col-span-4 w-2/4 items-center gap-2 max-sm:ml-2"
            >
              <div className="bg-white mx-0 w-7 h-7 rounded-full flex items-center">
                <Image
                  src="/assets/cellphone.png"
                  width={300}
                  height={500}
                  className="w-7"
                  alt=""
                />
              </div>
              <p>8220</p>
            </a>

            <a
              href="https://digital.login.gov.az/auth"
              className="flex justify-start col-span-4 items-center gap-2 max-sm:ml-2"
              target="_blank"
            >
              <Image
                src="/assets/ikta.png"
                width={0}
                height={0}
                sizes="100vw"
                className="ikta-image"
              />
            </a>

            <Link href={`/${currentLanguage}`} className="flex">
              <div
                onClick={handleScrollUp}
                className="absolute right-52 bottom-20 shadow-2xl shadow-black bg-[#5B2D90]  rounded-full w-14 h-14 flex justify-center items-center max-sm:hidden max-md:hidden max-lg:hidden max-xxl:hidden"
              >
                <Image
                  src="/assets/arrow.png"
                  width={5}
                  height={2}
                  className="w-5 h-2"
                  alt=""
                />
              </div>
            </Link>
          </ul>
        </div>
      </div>
      <div className=" border-b-[1px] border-white mx-8 border-opacity-30" />
      <div className="container max-w-[1000px] max-xxl:w-full mx-auto  py-2 flex flex-row justify-between items-center max-sm:justify-between text-white  font-medium text-[12px] max-sm:px-10">
        <div className="flex gap-5">
          <Link href={`/${currentLanguage}/${privacy}`} onClick={handleScrollUp}>
            <p> {translate('Privacy_policy', Language)}</p>
          </Link>
        </div>

        <p>&copy; {translate('All_rights_reserved', Language)}</p>

        <p className="max-sm:hidden max-xl:hidden">
          {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
