import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from './TranslationContext';

export const HomePopup = ({ ...pageProps }) => {
  const popup = pageProps?.PopupData?.data;
  const { translate, Language, currentLanguage } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === `/[lang]/registration`) {
      setIsOpen(false);
      localStorage.setItem('hasSeenMainPopup', 'true');
    } else {
      const hasSeenMainPopup = localStorage.getItem('hasSeenMainPopup');
      if (!hasSeenMainPopup && popup) {
        const timeout = setTimeout(() => {
          setIsOpen(true);
          localStorage.setItem('hasSeenMainPopup', 'true');
        }, 30000);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [router.pathname, currentLanguage]);

  const handleTriggerClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      {!isOpen && (
        <button
          onClick={handleTriggerClick}
          className="border p-2 rounded-3xl text-white text-[15px] w-[170px] h-[40px] max-sm:w-[120px] max-sm:h-[32px] max-sm:text-[11px] text-center mt-5 max-sm:mt-2  justify-center items-center overflow-hidden hidden"
        >
          {translate('Online_registration', Language)}
        </button>
      )}

      <Popup
        open={isOpen}
        onClose={() => setIsOpen(false)}
        modal
        nested
        contentStyle={{
          padding: '0px',
          borderRadius: '50px',
          borderColor: '#5b2d90',
          width: '919px',
          height: '492px',
          overflow: 'hidden',
        }}
      >
        {(close) => (
          <>
            {popup && (
              <div className="popup-content-2">
                <Image
                  src="/assets/popup/x.png"
                  className="absolute right-5 top-5 w-[40px] h-[42px]"
                  alt=""
                  width={40}
                  height={42}
                  onClick={() => {
                    close();
                    setIsOpen(false);
                    localStorage.setItem('hasSeenMainPopup', 'true');
                  }}
                />
                <Image
                  src={popup.image}
                  width={100}
                  height={100}
                  className="w-full"
                  alt=""
                />
                <div className="absolute top-32  w-[439px]  left-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: popup.content }}
                    className="text-white text-[28px] mb-5 font-light"
                  ></p>
                  <Link
                    href={`${currentLanguage}/registration`}
                    className="w-[254px] h-[45px] border-[1px] py-2.5 px-10 border-white text-[20px] text-white rounded-full   focus:0"
                  >
                    {translate('Online_registration', Language)}
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </Popup>
    </>
  );
};
